import axios from "axios";

import { getHeader } from "../../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}students`;

/**
 * Create new student
 * 
 * @param {*} student 
 * @returns 
 */
export function Create(student) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: student
    });
}

/**
 * Get all students
 * 
 * @param {*} param 
 * @returns 
 */
export function Students(param) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Branch students
 * 
 * @param {*} param 
 * @returns 
 */
export function BranchStudents(param) {
    return axios(`${URL}/branch/${param?.branchId}`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Count
 * 
 * @param {*} param 
 * @returns 
 */
export function Count(param) {
    return axios(`${URL}/count`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}
