import React from "react";
import Label from "./Label";
import Textarea from "./Textarea";

export default function LabelTextarea(props) {
    const {
        htmlFor,
        text,
        id,
        name,
        value,
        placeholder,
        className,
        required,
        minLength,
        maxLength,
        onChange } = props;

    return (
        <>
            <Label
                htmlFor={htmlFor}
                text={text} />
            <Textarea
                iid={id}
                name={name}
                value={value}
                className={className}
                placeholder={placeholder}
                required={required}
                maxLength={maxLength}
                minLength={minLength}
                onChange={onChange} />
        </>
    );
}