import React from "react";

export default function Textarea(props) {
    const { id,
        name,
        value,
        placeholder,
        className,
        onChange,
        required,
        maxLength,
        minLength } = props;
    const defaultClassName = "w-full form-checkbox rounded text-pink-500";
    const defaultPlaceholder = `Please enter the ${name}`
    const defaultID = "id";
    const defaultName = "name";

    return (
        <>
            <textarea
                id={id ?? defaultID}
                name={name ?? defaultName}
                value={value}
                className={className ?? defaultClassName}
                placeholder={placeholder ?? defaultPlaceholder}
                required={required}
                maxLength={maxLength}
                minLength={minLength}
                onChange={onChange}
            ></textarea>
        </>
    );
}