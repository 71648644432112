import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";
import { Create } from "./services/users";

import { Branches } from "./../branch/services/branches";
import Footer from "../components/Footer";
import { toastError, toastSuccess } from "../../_helpers/common";
import LabelInput from "../../common/components/LabelInput";
import LabelSelect from "../../common/components/LabelSelect";

/**
 * Manage user
 * 
 * @returns 
 */
export default function AddUser() {
    const [user, setUser] = useState({});
    /**
     * handleUserSubmit
     * 
     * @param {*} e 
     */
    const handleUserSubmit = (e) => {
        e.preventDefault();

        Create(user).then(user => {
            toastSuccess("New user account created successfully.");
            setUser({});
        }).catch(toastError);
    };

    // Fetch branch informations
    const [branchList, setBranchList] = useState({});
    useEffect(() => {
        Branches()
            .then((branches) => {
                setBranchList(branches?.data);
            });
    }, [])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar title="Ashique" />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="float-left font-semibold text-base text-blueGray-700">Add a New User</h3>
                                            <Link
                                                className="float-right bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                                                to="/users"
                                            ><i className="fas fa-chevron-left mr-2 text-white text-base"></i>BACK TO LIST</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="block w-full overflow-x-auto">
                                                {/* Payment Collection Form */}
                                                <form onSubmit={handleUserSubmit}>
                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="firstName"
                                                            text="First Name"
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder="Please enter the first name"
                                                            required="required"
                                                            value={user?.firstName ?? ""}
                                                            maxLength="20"
                                                            onChange={(event) => { setUser({ ...user, firstName: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="lastName"
                                                            text="Last Name"
                                                            id="lastName"
                                                            name="lastName"
                                                            placeholder="Please enter the last name"
                                                            required="required"
                                                            value={user?.lastName ?? ""}
                                                            maxLength="20"
                                                            onChange={(event) => { setUser({ ...user, lastName: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="email"
                                                            htmlFor="email"
                                                            text="Email"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Please enter the user's email address"
                                                            required="required"
                                                            value={user?.email ?? ""}
                                                            maxLength="75"
                                                            onChange={(event) => { setUser({ ...user, email: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="mobile"
                                                            text="Mobile"
                                                            id="mobile"
                                                            name="mobile"
                                                            placeholder="Please enter the user's mobile number"
                                                            required="required"
                                                            value={user?.mobile ?? ""}
                                                            minLength="10"
                                                            maxLength="10"
                                                            pattern="\d*"
                                                            onChange={(event) => { setUser({ ...user, mobile: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelSelect
                                                            htmlFor="branch"
                                                            text="Branch"
                                                            id="branch"
                                                            name="branch"
                                                            placeholder="Please choose branch"
                                                            required="required"
                                                            value={user?.branch ?? ""}
                                                            options={branchList}
                                                            optionId="id"
                                                            optionText="name"
                                                            onChange={(event) => { setUser({ ...user, branch: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="password"
                                                            htmlFor="password"
                                                            text="Password"
                                                            id="password"
                                                            name="password"
                                                            placeholder="Please enter the password"
                                                            required="required"
                                                            value={user?.password ?? ""}
                                                            maxLength="30"
                                                            onChange={(event) => { setUser({ ...user, password: event?.target?.value }) }} />
                                                    </div>

                                                    <div className='flex justify-center items-center mt-6'>
                                                        <button
                                                            className={`bg-pink-500 py-2 px-4 text-sm text-white rounded font-bold focus:outline-none focus:border-green-dark`}
                                                        ><i className="fas fa-save mr-2 text-white text-base"></i>NEW USER</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
