import React from "react";

/**
 * Dropdown 
 * 
 * @param {*} props 
 * @returns 
 */
export default function Select(props) {
    const {
        id,
        name,
        value,
        placeholder,
        className,
        onChange,
        required,
        options,
        optionId,
        optionText } = props;
    const defaultClassName = "w-full form-checkbox rounded text-pink-500";
    const defaultPlaceholder = placeholder ?? `Please enter the ${name}`
    const defaultID = "id";
    const defaultName = "name";
    const defaultRequired = "required";
    const defaultOptionId = optionId ?? "id";
    const defaultOptionText = optionText ?? "name";
    const defaultValue = "";

    return (
        <>
            <select
                id={id ?? defaultID}
                name={name ?? defaultName}
                value={value ?? defaultValue}
                className={className ?? defaultClassName}
                required={required ?? defaultRequired}
                onChange={onChange}>
                <option value="">{defaultPlaceholder}</option>
                {(options && options.length > 0) && options.map((option, index) =>
                (<option key={index} value={option[defaultOptionId]}>{option[defaultOptionText]}</option>
                ))}
            </select>
        </>
    );
}