import React from "react";
import { Link } from "react-router-dom";

import UserDropdown from "./UserDropdown";

export default function Sidebar() {
    const [collapseShow, setCollapseShow] = React.useState("hidden");
    const permission = JSON.parse(localStorage.getItem('user'))?.role;

    /**
     * Private route for authorized users
     * 
     * @param {*} param0 
     * @returns 
     */
    function PrivateLink({ to: to, permissions: permissions, className: className, iconClassName: iconClassName, labelName: labelName, liClassName: liClassName }) {
        let authed = (permissions.indexOf(permission) !== -1)

        return (
            authed ?
                <li className={liClassName} >
                    <Link className={className} to={to}><i className={iconClassName}></i>{labelName}
                    </Link></li> : ""
        )
    }

    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <Link
                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                        to="/">
                        {process.env.REACT_APP_NAME}
                    </Link>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        {/* <li className="inline-block relative">
                            <NotificationDropdown />
                        </li> */}
                        <li className="inline-block relative">
                            <UserDropdown />
                        </li>
                    </ul>
                    {/* Collapse */}
                    <div
                        className={
                            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                            collapseShow
                        }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                                        to="/"
                                    >
                                        {process.env.REACT_APP_NAME}
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow("hidden")}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Form */}
                        {/* <form className="mt-6 mb-4 md:hidden">
                            <div className="mb-3 pt-0">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                                />
                            </div>
                        </form> */}
                        {/* Navigation */}
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">

                            <PrivateLink to="/"
                                permissions={["admin", "subadmin"]}
                                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                                iconClassName="fas fa-home opacity-75 mr-2 text-sm"
                                liClassName="items-center"
                                labelName="Dashboard">
                            </PrivateLink>

                            <PrivateLink to="/collections/add"
                                permissions={["admin", "subadmin"]}
                                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                                iconClassName="fas fa-credit-card opacity-75 mr-2 text-sm"
                                liClassName="items-center"
                                labelName="Fee Collection">
                            </PrivateLink>

                            <PrivateLink to="/students/add"
                                permissions={["admin", "subadmin"]}
                                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                                iconClassName="fas fa-user-plus opacity-75 mr-2 text-sm"
                                liClassName="items-center"
                                labelName="Registration">
                            </PrivateLink>
                        </ul>

                        {permission === "admin" ?
                            <>
                                <hr className="my-4 md:min-w-full" />
                                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                    Administration
                            </h6>
                            </> : ""
                        }

                        {/* Navigation */}

                        {permission === "admin" ?
                            <>
                                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                                    <PrivateLink to="/collections"
                                        permissions={["admin"]}
                                        className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
                                        iconClassName="fas fa-credit-card mr-2 text-blueGray-400 text-base"
                                        liClassName="inline-flex"
                                        labelName="Collections">
                                    </PrivateLink>

                                    <PrivateLink to="/students"
                                        permissions={["admin"]}
                                        className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
                                        iconClassName="fas fa-graduation-cap mr-2 text-blueGray-400 text-base"
                                        liClassName="inline-flex"
                                        labelName="Students">
                                    </PrivateLink>

                                    <PrivateLink to="/users"
                                        permissions={["admin"]}
                                        className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
                                        iconClassName="fas fa-users mr-2 text-blueGray-400 text-base"
                                        liClassName="inline-flex"
                                        labelName="Users">
                                    </PrivateLink>

                                    <hr className="my-4 md:min-w-full" />

                                    <PrivateLink to="/branches"
                                        permissions={["admin"]}
                                        className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
                                        iconClassName="fas fa-location-arrow mr-2 text-blueGray-400 text-base"
                                        liClassName="inline-flex"
                                        labelName="Branches">
                                    </PrivateLink>

                                    <PrivateLink to="/courses"
                                        permissions={["admin"]}
                                        className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
                                        iconClassName="fas fa-book mr-2 text-blueGray-400 text-base"
                                        liClassName="inline-flex"
                                        labelName="Courses">
                                    </PrivateLink>
                                </ul>
                            </>
                            : ""}
                        <hr className="my-4 md:min-w-full" />
                        <ul>
                            <PrivateLink to="#"
                                permissions={["admin", "subadmin"]}
                                className="text-blueGray-300 text-xs uppercase py-3 font-bold block"
                                iconClassName="fas fa-tools text-blueGray-300 mr-2 text-sm"
                                liClassName="items-center"
                                labelName="Settings (Coming soon)">
                            </PrivateLink>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}