import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Moment from 'moment';
import ordinal from 'ordinal';

import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";

import { Collections } from "./services/collections"
import Footer from "../components/Footer";
import Input from "../../common/components/Input";

export default function ListCollection() {

    const [criterias, setCriterias] = useState({});

    // Fetch collection informations
    const [collectionList, setCollectionList] = useState({});
    const [apiCollectionFlag, setApiCollectionFlag] = useState(false);

    useEffect(() => {
        getColloection()
    }, [])

    let getColloection = function () {
        Collections(criterias)
            .then(collections => {
                setCollectionList(collections.data);
                setApiCollectionFlag(true)
            });
    }

    let handleSearch = function (event) {
        if (event.key === 'Enter') {
            setCollectionList({});
            setApiCollectionFlag(false);
            getColloection();
        }
    }

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-blueGray-700">List Collections</h3>
                                        </div>
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                            <Link
                                                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                                                to="/collections/add"
                                            ><i className="fas fa-plus mr-2 text-white text-base"></i>COLLECT</Link>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="block w-full overflow-x-auto">
                                    {/* Projects table */}
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Student Name
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Mobile
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Student Email
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Course
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Branch
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                                                    Amount(&#8377;)
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Instal.
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    SMS
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Created Date
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key="-1">
                                                <td className="align-middle text-xs p-4">
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Student Name"
                                                        value={criterias.name ?? ""}
                                                        onChange={(event) => { setCriterias({ ...criterias, name: event?.target?.value }) }}
                                                        onKeyDown={handleSearch} />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <Input
                                                        type="number"
                                                        id="mobile"
                                                        name="mobile"
                                                        placeholder="Mobile"
                                                        value={""}
                                                        onChange={(event) => { }} />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <Input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={""}
                                                        onChange={(event) => { }} />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                                <td className="align-middle text-xs p-4">
                                                    <input />
                                                </td>
                                            </tr>
                                            {(collectionList.length > 0) && collectionList.map((collection, index) => (
                                                <tr key={index}>
                                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                        {collection.Student?.firstName} {collection.Student?.lastName}
                                                    </th>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection.Student?.mobile}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection.Student?.email}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection.Course?.name}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection?.Branch.name}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                        {collection.amount}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {ordinal(collection.installment)}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {(collection.smsStatus === false) ? <i className="fas fa-times mr-2 text-base"></i> : <i className="fas fa-check mr-2 text-base"></i>}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {Moment(collection.createdDate).format('DD MMM YYYY hh:mm A')}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        --
                                                    </td>
                                                </tr>
                                            ))}
                                            {(apiCollectionFlag === false || collectionList.length === 0) &&
                                                <tr><th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" colSpan="7">{apiCollectionFlag === true ? "No data available" : "Loading..."}</th></tr>
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
