import React from "react";
import Label from "./Label";
import Input from "./Input";

export default function LabelInput(props) {
    const {
        htmlFor,
        text,
        id,
        name,
        value,
        placeholder,
        required,
        minLength,
        maxLength,
        type,
        pattern,
        onChange } = props;

    return (
        <>
            <Label
                htmlFor={htmlFor}
                text={text} />
            <Input
                type={type}
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                required={required}
                value={value}
                maxLength={maxLength}
                minLength={minLength}
                pattern={pattern}
                onChange={onChange} />
        </>
    );
}