import axios from "axios";

import { getHeader } from "../../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}collections`;

/**
 * Create new collection
 * 
 * @param {*} collection 
 * @returns 
 */
export function Create(collection) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: collection
    });
}

/**
 * Get all collections
 * 
 * @param {*} params 
 * @returns 
 */
export function Collections(params) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        params: params
    });
}

/**
 * Get collection by student
 * 
 * @param {\} param 
 * @returns 
 */
export function StudentCollections(param) {
    return axios(`${URL}/student/${param?.studentId}`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Count 
 * 
 * @param {\} param 
 * @returns 
 */
export function Count(param) {
    return axios(`${URL}/count`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Chart
 * 
 * @param {\} param 
 * @returns 
 */
export function Chart(param) {
    return axios(`${URL}/chart`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}