import axios from "axios";

import { getHeader } from "../../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}branches`;

/**
 * Create new branch
 * 
 * @param {*} branch 
 * @returns 
 */
export function Create(branch) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: branch
    });
}

/**
 * List all branches
 * 
 * @param {*} param 
 * @returns 
 */
export function Branches(param) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Get count
 * 
 * @param {*} param 
 * @returns 
 */
export function Count(param) {
    return axios(`${URL}/count`, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}