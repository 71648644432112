import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Login from './views/authentication/Login';
import Logout from './views/authentication/Logout';
import Dashboard from './views/dashboard/Dashboard'
import AddBranch from './views/dashboard/branch/Add';
import ListBranch from './views/dashboard/branch/List';
import AddCourse from "./views/dashboard/course/Add";
import ListCourse from "./views/dashboard/course/List";
import AddStudent from "./views/dashboard/student/Add";
import ListStudent from "./views/dashboard/student/List";
import AddUser from "./views/dashboard/user/Add";
import ListUser from "./views/dashboard/user/List";
import AddCollection from "./views/dashboard/collection/Add";
import ListCollection from "./views/dashboard/collection/List";
import Profile from "./views/dashboard/user/Profile";

let permission = JSON.parse(localStorage.getItem('user'))?.role;


/**
 * Private route for authorized users
 * 
 * @param {*} param0 
 * @returns 
 */
function PrivateRoute({ component: Component, permissions, ...rest }) {
  const history = useHistory();
  let authed = (permissions.indexOf(permission) !== -1)

  if (!permission) {
    return (
      <>
        <Route path="/login" component={Login} />
        <Redirect from="/" to="/login" />
      </>
    )
  }
  else
    return (
      <Route
        {...rest}
        render={(props) => authed === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
    )
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Redirect from="/" to="/login" /> */}

      <PrivateRoute permissions={['admin']} path='/branches/add' component={AddBranch} />
      <PrivateRoute permissions={['admin']} path='/branches' component={ListBranch} />

      <PrivateRoute permissions={['admin']} path='/courses/add' component={AddCourse} />
      <PrivateRoute permissions={['admin']} path='/courses' component={ListCourse} />

      <PrivateRoute permissions={['admin', 'subadmin']} path='/students/add' component={AddStudent} />
      <PrivateRoute permissions={['admin']} path='/students' component={ListStudent} />

      <PrivateRoute permissions={['admin', 'subadmin']} path='/users/add' component={AddUser} />
      <PrivateRoute permissions={['admin']} path='/users' component={ListUser} />

      <PrivateRoute permissions={['admin', 'subadmin']} path='/collections/add' component={AddCollection} />
      <PrivateRoute permissions={['admin']} path='/collections' component={ListCollection} />

      <PrivateRoute permissions={['admin', 'subadmin']} path='/profile' component={Profile} />
      <PrivateRoute permissions={['admin', 'subadmin']} path='/logout' component={Logout} />
      <Route path="/login" component={Login} />
      <PrivateRoute permissions={['admin', 'subadmin']} path='/' component={Dashboard} />


    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
