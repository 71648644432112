import React from "react";

export default function Input(props) {
    const { type,
        id,
        name,
        value,
        placeholder,
        className,
        onChange,
        onKeyDown,
        required,
        maxLength,
        minLength,
        pattern } = props;
    const defaultType = "text";
    const defaultClassName = "w-full form-checkbox rounded text-pink-500";
    const defaultPlaceholder = `Please enter the ${name}`
    const defaultID = "id";
    const defaultName = "name";
    const defaultRequired = "required";
    const defaultPattern = ".*";

    return (
        <>
            <input
                type={type ?? defaultType}
                id={id ?? defaultID}
                name={name ?? defaultName}
                value={value}
                placeholder={placeholder ?? defaultPlaceholder}
                required={required ?? defaultRequired}
                className={className ?? defaultClassName}
                value={value}
                maxLength={maxLength}
                minLength={minLength}
                pattern={pattern ?? defaultPattern}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
        </>
    );
}