import React, { useEffect, useState } from "react";

import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";
import { UpdateMe, Me } from "./services/users";

import Footer from "../components/Footer";
import { toastError, toastSuccess } from "../../_helpers/common";


/**
 * Manage user
 * 
 * @returns 
 */
export default function Profile() {
    /**
     * handleUserSubmit
     * 
     * @param {*} e 
     */
    const handleUserSubmit = (e) => {
        e.preventDefault();

        UpdateMe({
            email: e.target.elements.email?.value,
            firstName: e.target.elements.firstName?.value,
            lastName: e.target.elements.lastName?.value,
            mobile: e.target.elements.mobile?.value,
            password: e.target.elements.password?.value
        }).then(user => {
            e.target.elements.password.value = "";
            toastSuccess("User account updated successfully.");
        }).catch(toastError);
    };


    const handleChange = (e) => {
        e.setState({ value: e.target.value });
    }

    // Fetch user informations
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: ""
    });
    useEffect(() => {
        Me()
            .then((userData) => {
                setUser(userData?.data);
            });
    }, [])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar title="Ashique" />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="float-left font-semibold text-base text-blueGray-700">Update Profile</h3>
                                            <br></br>
                                            <br></br>
                                            <div className="block w-full overflow-x-auto">
                                                {/* Payment Collection Form */}
                                                <form onSubmit={handleUserSubmit}>
                                                    <div>
                                                        <label htmlFor='firstName'>First Name</label>
                                                        <input type="text" id="firstName" value={user?.firstName} onChange={handleChange} className="w-full form-checkbox rounded text-pink-500" placeholder="Please enter the first name" required />
                                                    </div>

                                                    <div>
                                                        <label htmlFor='lastName'>First Name</label>
                                                        <input type="text" id="lastName" value={user?.lastName} onChange={handleChange} className="w-full form-checkbox rounded text-pink-500" placeholder="Please enter the last name" required />
                                                    </div>

                                                    <div>
                                                        <label htmlFor='email'>Email</label>
                                                        <input type="text" id="email" value={user?.email} onChange={handleChange} className="w-full form-checkbox rounded text-pink-500" placeholder="Please enter the user's email address" required />
                                                    </div>

                                                    <div>
                                                        <label htmlFor='mobile'>Mobile</label>
                                                        <input type="number" id="mobile" value={user?.mobile} onChange={handleChange} className="w-full form-checkbox rounded text-pink-500" placeholder="Please enter the user's mobile number" required />
                                                    </div>

                                                    <div>
                                                        <label htmlFor='password'>Password</label>
                                                        <input type="password" id="password" className="w-full form-checkbox rounded text-pink-500" placeholder="Please enter the password" />
                                                    </div>

                                                    <div className='flex justify-center items-center mt-6'>
                                                        <button
                                                            className={`bg-pink-500 py-2 px-4 text-sm text-white rounded font-bold focus:outline-none focus:border-green-dark`}
                                                        ><i className="fas fa-save mr-2 text-white text-base"></i>UPDATE</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
