import React from "react";

export default function Label(props) {
    const { htmlFor, text } = props;
    const defaultHtmlFor = "";
    const defaultText = "";

    return (
        <>
            <label htmlFor={htmlFor ?? defaultHtmlFor}>{text ?? defaultText}</label>
        </>
    );
}