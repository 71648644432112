import React, { useEffect, useState } from "react";
import moment from "moment";
import ordinal from 'ordinal';

import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";

import { Branches } from "../branch/services/branches";
import { Create, StudentCollections } from "./services/collections";
import { BranchStudents } from "../student/services/students";

import Footer from "../components/Footer";
import { getCurrentUser, isSubAdmin, toastError, toastSuccess } from "../../_helpers/common";
import LabelSelect from "../../common/components/LabelSelect";
import { Course } from "../course/services/courses";
import LabelTextarea from "../../common/components/LabelTextarea";
import LabelInput from "../../common/components/LabelInput";


/**
 * Manage collection
 * 
 * @returns 
 */
export default function AddCollection() {
    const [collection, setCollection] = useState({});
    /**
     * handleCollectionSubmit
     * 
     * @param {*} e 
     */
    const handleCollectionSubmit = (e) => {
        e.preventDefault();
        const brancId = isSubAdmin() ? getCurrentUser('branch') : curStudent?.branch;

        Create(collection).then(collection => {
            toastSuccess("New Collection created successfully.");
            setCollection({});
        }).catch(toastError);
    };

    // Fetch collection informations
    const [branchList, setBranchList] = useState({});
    useEffect(() => {
        Branches()
            .then(branches => {
                setBranchList(branches.data);
            });

        if (isSubAdmin()) {
            fetchBranch(getCurrentUser('branch'));
        }
    }, [])

    const [studentList, setStudentList] = useState({});
    /**
     * handleBranchChange
     * 
     * @param {*} e 
     * @returns 
     */
    const handleBranchChange = (e) => {
        e.preventDefault();

        let branchId = e.target.value;
        if (!branchId) {
            setCurStudent({});
            setPayments({});
            setStudentList({});
            return;
        }
        setCollection({ ...collection, branch: branchId });
        fetchBranch(branchId)
    };

    /**
     * Fetch branch
     * 
     * @param {*} branchId 
     */
    function fetchBranch(branchId) {
        BranchStudents({ branchId: branchId })
            .then(students => {
                setStudentList(students.data);
            });
    }

    const [payments, setPayments] = useState({});
    const [curStudent, setCurStudent] = useState({});
    const [course, setCourse] = useState({});
    /**
     * handleStudentSelect
     * 
     * @param {*} e 
     * @returns 
     */
    const handleStudentSelect = (e) => {
        e.preventDefault();

        let studentId = e.target.value;
        setCollection({ ...collection, student: studentId });

        setPayments({});
        setCurStudent({});
        setCourse({});

        StudentCollections({ studentId: studentId }).then(payments => {
            setPayments(payments?.data?.collections);
            setCurStudent(payments?.data?.student);
            setCourse(payments?.data?.course);
        });
    };

    const [installments, setInstallments] = useState({});
    useEffect(() => {
        let minInstallment = payments[0]?.installment ?? 0;
        setInstallments({});
        let tmpInstallments = [];
        for (let i = minInstallment + 1; i <= course.noTerms; i++) {
            tmpInstallments.push({ "id": i })
        }
        setInstallments(tmpInstallments)
    }, [payments, course])



    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar title="Ashique" />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full xl:w-6/12 mb-10 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-blueGray-700">Add a New Collection</h3>
                                            <br></br>
                                            <div className="block w-full overflow-x-auto">
                                                {/* Payment Collection Form */}
                                                <form onSubmit={handleCollectionSubmit}>
                                                    {!isSubAdmin() &&
                                                        <div>
                                                            <LabelSelect
                                                                htmlFor="branch"
                                                                text="Branch"
                                                                id="branch"
                                                                name="branch"
                                                                placeholder="Please choose one branch"
                                                                required="required"
                                                                value={collection?.branch ?? ""}
                                                                options={branchList}
                                                                optionId="id"
                                                                optionText="name"
                                                                onChange={handleBranchChange.bind(this)} />
                                                        </div>
                                                    }

                                                    <div>
                                                        <LabelSelect
                                                            htmlFor="student"
                                                            text="Student"
                                                            id="student"
                                                            name="student"
                                                            placeholder="Please choose a student"
                                                            required="required"
                                                            value={collection?.student ?? ""}
                                                            options={studentList}
                                                            optionId="id"
                                                            optionText="firstName"
                                                            onChange={handleStudentSelect.bind(this)} />
                                                    </div>

                                                    <div>
                                                        <LabelSelect
                                                            htmlFor="installment"
                                                            text="Student"
                                                            id="installment"
                                                            name="installment"
                                                            placeholder="Please choose number of installment"
                                                            required="required"
                                                            value={collection?.installment ?? ""}
                                                            options={installments}
                                                            optionId="id"
                                                            optionText="id"
                                                            onChange={(event) => { setCollection({ ...collection, installment: parseInt(event?.target?.value) }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="number"
                                                            htmlFor="amount"
                                                            text="Amount"
                                                            id="amount"
                                                            name="amount"
                                                            placeholder="Please enter collection amount"
                                                            required="required"
                                                            value={collection?.amount ?? ""}
                                                            maxLength="5"
                                                            onChange={(event) => { setCollection({ ...collection, amount: parseInt(event?.target?.value) }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelTextarea
                                                            htmlFor="note"
                                                            text="Note"
                                                            id="note"
                                                            name="note"
                                                            placeholder="Please enter the collection note (if any)"
                                                            value={collection?.note ?? ""}
                                                            maxLength="50"
                                                            onChange={(event) => { setCollection({ ...collection, note: event?.target?.value }) }} />
                                                    </div>

                                                    <div className='flex justify-center items-center mt-6'>
                                                        <button
                                                            className={`bg-pink-500 py-2 px-4 text-sm text-white rounded font-bold focus:outline-none focus:border-green-dark`}
                                                        ><i className="fas fa-save mr-2 text-white text-base"></i>COLLECT</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full xl:w-6/12 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-blueGray-700">
                                                Payment History
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="block w-full overflow-x-auto">
                                    {/* Projects table */}
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Instal.
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                                                    Amount(&#8377;)
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    Date & Time
                                                </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    SMS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(payments && payments.length > 0) ? payments.map((payment, index) =>
                                            (<tr key={index}>
                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                    {ordinal(payment.installment)}
                                                </th>
                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-right">
                                                    {payment.amount}
                                                </th>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {moment(payment.createdDate).format('D MMM YYYY h:mm A')}
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {(payment.smsStatus === false) ? <i className="fas fa-times mr-2 text-base"></i> : <i className="fas fa-check mr-2 text-base"></i>}
                                                </td>
                                            </tr>)) : (<tr><th colSpan="2" className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                No Record Available.
                                            </th></tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
