import React, { useEffect, useState } from "react";

import Navbar from "./components/Navbar.js";
import Sidebar from "./components/Sidebar.js";
import LineChart from "./components/LineChart.js";
// import BarChart from "./components/BarChart.js";
import { Collections, Count as CollectionCount, Chart } from "./collection/services/collections";
import { Count as BranchCount } from "./branch/services/branches";
import { Count as StudentCount } from "./student/services/students";
import { Count as CourseCount } from "./course/services/courses";
import Footer from "./components/Footer";

import Moment from 'moment';
import { Link } from "react-router-dom";
import { isSubAdmin } from "../_helpers/common.js";

export default function Dashboard() {

    // Fetch collection informations
    const [collectionList, setCollectionList] = useState({});
    const [branchCount, setBranchCount] = useState(0);
    const [studentCount, setStudentCount] = useState(0);
    const [courseCount, setCourseCount] = useState(0);
    const [collectionCount, setCollectionCount] = useState(0);
    const [apiCollectionFlag, setApiCollectionFlag] = useState(false);


    useEffect(() => {
        Collections()
            .then(collections => {
                setCollectionList(collections.data);
                setApiCollectionFlag(true);
            });

        CollectionCount().then(count => {
            setCollectionCount(count.data);
        });

        // Chart().then(chart => {
        //     console.log(chart.data);
        // });


        BranchCount().then(count => {
            setBranchCount(count.data);
        });

        StudentCount().then(count => {
            setStudentCount(count.data);
        });

        CourseCount().then(count => {
            setCourseCount(count.data);
        });
    }, [])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12">
                    <div className="px-4 md:px-10 mx-auto w-full">
                        <div>
                            {/* Card stats */}
                            <div className="flex flex-wrap">
                                {!isSubAdmin() &&
                                    <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                            <div className="flex-auto p-4">
                                                <div className="flex flex-wrap">
                                                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                                                            Branches
                                                        </h5>
                                                        <span className="font-semibold text-xl text-blueGray-700">
                                                            {branchCount}
                                                        </span>
                                                    </div>
                                                    <div className="relative w-auto pl-4 flex-initial">
                                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                                                            <i className="fa fa-location-arrow"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-wrap">
                                                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                                                        Students
                          </h5>
                                                    <span className="font-semibold text-xl text-blueGray-700">
                                                        {studentCount}
                                                    </span>
                                                </div>
                                                <div className="relative w-auto pl-4 flex-initial">
                                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-orange-500">
                                                        <i className="fas fa-graduation-cap"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="text-sm text-blueGray-400 mt-4">
                                                <span className="text-red-500 mr-2">
                                                    <i className="fas fa-arrow-down"></i> 3.48%
                        </span>
                                                <span className="whitespace-nowrap">
                                                    Since last week
                        </span>
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-wrap">
                                                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                                                        Courses
                          </h5>
                                                    <span className="font-semibold text-xl text-blueGray-700">
                                                        {courseCount}
                                                    </span>
                                                </div>
                                                <div className="relative w-auto pl-4 flex-initial">
                                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-pink-500">
                                                        <i className="fas fa-book"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="text-sm text-blueGray-400 mt-4">
                                                <span className="text-orange-500 mr-2">
                                                    <i className="fas fa-arrow-down"></i> 1.10%
                        </span>
                                                <span className="whitespace-nowrap">
                                                    Since yesterday
                        </span>
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-wrap">
                                                <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                                                        Transaction
                          </h5>
                                                    <span className="font-semibold text-xl text-blueGray-700">
                                                        {collectionCount}
                                                    </span>
                                                </div>
                                                <div className="relative w-auto pl-4 flex-initial">
                                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-lightBlue-500">
                                                        <i className="fas fa-credit-card"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="text-sm text-blueGray-400 mt-4">
                                                <span className="text-emerald-500 mr-2">
                                                    <i className="fas fa-arrow-up"></i> 12%
                        </span>
                                                <span className="whitespace-nowrap">
                                                    Since last month
                        </span>
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap">
                        {/* <LineChart /> */}
                        {/* <BarChart /> */}
                    </div>
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-blueGray-700">
                                                Recent Collections
                                            </h3>
                                        </div>
                                        {!isSubAdmin &&
                                            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                                <Link
                                                    className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                                                    to="/collections"
                                                ><i className="fas fa-list mr-2 text-white text-base"></i>See all</Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="block w-full overflow-x-auto">
                                    {/* Projects table */}
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    STUDENT NAME
                        </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    MOBILE
                        </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                                                    AMOUNT(&#8377;)
                        </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    STUDENT EMAIL
                        </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    DATE & Time
                        </th>

                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    COURSE
                        </th>
                                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                    BRANCH
                        </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {collectionList && collectionList.length > 0 && collectionList.map((collection, index) => (
                                                <tr key={index}>
                                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                        {collection?.Student?.firstName} {collection?.Student?.lastName}
                                                    </th>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection.Student?.mobile}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                        {collection.amount}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection?.Student?.email}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {Moment(collection.createdDate).format('D MMM YYYY')}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection?.Course?.name}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        {collection?.Branch?.name}
                                                    </td>
                                                </tr>
                                            ))}

                                            {(apiCollectionFlag === false || collectionList.length === 0) &&
                                                <tr><th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" colSpan="4">{apiCollectionFlag === true ? "No data available" : "Loading..."}</th></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}