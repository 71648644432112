import axios from "axios"

import { getHeader } from "../../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}users`;

/**
 * Create new user
 * 
 * @param {*} user 
 * @returns 
 */
export function Create(user) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: user
    });
}

/**
 * Get all users
 * 
 * @param {*} params 
 * @returns 
 */
export function Users(params) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        data: params
    });
}

/**
 * Get current loged in user information
 * 
 * @returns \
 */
export function Me() {
    return axios(`${URL}/me`, {
        method: 'GET',
        headers: getHeader()
    });
}

/**
 * Update current profile
 * 
 * @param {*} user 
 * @returns 
 */
export function UpdateMe(user) {
    return axios(`${URL}/me`, {
        method: 'PUT',
        headers: getHeader(),
        data: user
    });
}