import { React, useState } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";
import { Create } from "./services/courses"
import { toastError, toastSuccess } from "../../_helpers/common";
import LabelInput from "../../common/components/LabelInput";

/**
 * Manage course
 * 
 * @returns 
 */
export default function AddCourse() {
    const [course, setCourse] = useState({});
    // Handle course submit
    const handleCourseSubmit = (e) => {
        e.preventDefault();

        Create(course).then(course => {
            toastSuccess("New course created successfully.");
            setCourse({});
        }).catch(toastError);
    };

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar title="Ashique" />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="float-left font-semibold text-base text-blueGray-700">Add a New Course</h3>
                                            <Link
                                                className="float-right bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                                                to="/courses"
                                            ><i className="fas fa-chevron-left mr-2 text-white text-base"></i>BACK TO LIST</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="block w-full overflow-x-auto">
                                                {/* Payment Collection Form */}
                                                <form onSubmit={handleCourseSubmit}>
                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="name"
                                                            text="Course"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Please enter the course name"
                                                            required="required"
                                                            value={course?.name ?? ""}
                                                            maxLength="50"
                                                            onChange={(event) => { setCourse({ ...course, name: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="number"
                                                            htmlFor="fee"
                                                            text="Fee"
                                                            id="fee"
                                                            name="fee"
                                                            placeholder="Please enter the fee"
                                                            required="required"
                                                            value={course?.fee ?? ""}
                                                            maxLength="5"
                                                            onChange={(event) => { setCourse({ ...course, fee: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="number"
                                                            htmlFor="noTerms"
                                                            text="Terms"
                                                            id="noTerms"
                                                            name="noTerms"
                                                            placeholder="Please enter the number of terms"
                                                            required="required"
                                                            value={course?.noTerms ?? ""}
                                                            maxLength="2"
                                                            onChange={(event) => { setCourse({ ...course, noTerms: event?.target?.value }) }} />
                                                    </div>

                                                    <div className='flex justify-center items-center mt-6'>
                                                        <button
                                                            className={`bg-pink-500 py-2 px-4 text-sm text-white rounded font-bold focus:outline-none focus:border-green-dark`}
                                                        ><i className="fas fa-save mr-2 text-white text-base"></i>COURSE</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
