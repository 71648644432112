import React, { useEffect, useState } from "react";

import Navbar from "./../components/Navbar";
import Sidebar from "./../components/Sidebar";
import { Create } from "./services/students";

import { Branches } from "./../branch/services/branches";
import { Courses } from "./../course/services/courses";
import Footer from "../components/Footer";
import { toastError, toastSuccess } from "../../_helpers/common";
import LabelInput from "../../common/components/LabelInput";
import LabelSelect from "../../common/components/LabelSelect";

/**
 * Manage student
 * 
 * @returns 
 */
export default function AddStudent() {
    const [student, setStudent] = useState({});

    // Handle student submit
    const handleStudentSubmit = (e) => {
        e.preventDefault();

        Create(student).then(student => {
            toastSuccess("New student enrolled successfully.");
            setStudent({});
        }).catch(toastError);
    };

    const [branchList, setBranchList] = useState({});
    const [courseList, setCourseList] = useState({});

    // Fetch student informations
    useEffect(() => {
        Branches()
            .then(branches => {
                setBranchList(branches.data);
            });

        Courses()
            .then(courses => {
                setCourseList(courses.data);
            });
    }, [])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar />
                {/* Header */}
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-blueGray-700">Add a New Student</h3>

                                            <br></br>
                                            <div className="block w-full overflow-x-auto">
                                                {/* Payment Collection Form */}
                                                <form onSubmit={handleStudentSubmit}>
                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="firstName"
                                                            text="First Name"
                                                            id="firstName"
                                                            name="firstName"
                                                            placeholder="Please enter the student first name"
                                                            required="required"
                                                            value={student?.firstName ?? ""}
                                                            maxLength="20"
                                                            onChange={(event) => { setStudent({ ...student, firstName: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="lastName"
                                                            text="Last Name"
                                                            id="lastName"
                                                            name="lastName"
                                                            placeholder="Please enter the student last name"
                                                            required="required"
                                                            value={student?.lastName ?? ""}
                                                            maxLength="20"
                                                            onChange={(event) => { setStudent({ ...student, lastName: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="email"
                                                            htmlFor="lastName"
                                                            text="Email"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Please enter student's email address"
                                                            required="required"
                                                            value={student?.email ?? ""}
                                                            maxLength="75"
                                                            onChange={(event) => { setStudent({ ...student, email: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelInput
                                                            type="text"
                                                            htmlFor="mobile"
                                                            text="Mobile"
                                                            id="mobile"
                                                            name="mobile"
                                                            placeholder="Please enter student's mobile number"
                                                            required="required"
                                                            value={student?.mobile ?? ""}
                                                            minLength="10"
                                                            maxLength="10"
                                                            pattern="\d*"
                                                            onChange={(event) => { setStudent({ ...student, mobile: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelSelect
                                                            htmlFor="branch"
                                                            text="Branch"
                                                            id="branch"
                                                            name="branch"
                                                            placeholder="Please choose one branch"
                                                            required="required"
                                                            value={student?.branch ?? ""}
                                                            options={branchList}
                                                            optionId="id"
                                                            optionText="name"
                                                            onChange={(event) => { setStudent({ ...student, branch: event?.target?.value }) }} />
                                                    </div>

                                                    <div>
                                                        <LabelSelect
                                                            htmlFor="course"
                                                            text="Course"
                                                            id="course"
                                                            name="course"
                                                            placeholder="Please choose one course"
                                                            required="required"
                                                            value={student?.course ?? ""}
                                                            options={courseList}
                                                            optionId="id"
                                                            optionText="name"
                                                            onChange={(event) => { setStudent({ ...student, course: event?.target?.value }) }} />
                                                    </div>

                                                    <div className='flex justify-center items-center mt-6'>
                                                        <button
                                                            className="bg-pink-500 py-2 px-4 text-sm text-white rounded font-bold focus:outline-none focus:border-green-dark"
                                                        ><i className="fas fa-save mr-2 text-white text-base"></i>ENROL</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}
