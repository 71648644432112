import { toast } from 'react-toastify';

/**
 * Return the token 
 * 
 * @returns 
 */
export function getToken() {
    return "Bearer " + JSON.parse(localStorage.getItem('user')).token;
}

/**
 * Get header
 * 
 * @param {*} params 
 * @returns 
 */
export function getHeader(params) {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getToken(),
    };
}

/**
 * Toast Error
 * 
 * @param {*} error 
 */
export function toastError(error) {
    toast.error("ERROR : " + error?.response?.data?.message);
}

/**
 * Toast Success
 * 
 * @param {*} success 
 */
export function toastSuccess(success) {
    toast.success("SUCCESS : " + success);
}

/**
 * Check subadmin or not
 * 
 * @returns 
 */
export function isSubAdmin() {
    return JSON.parse(localStorage.getItem('user')).role === "subadmin" ? true : false;
}

export function getCurrentUser(param) {
    if (param) {
        return JSON.parse(localStorage.getItem('user'))[param] ?? false;
    }

    return JSON.parse(localStorage.getItem('user'));
}