import axios from "axios";

import { getHeader } from "../../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}courses`;

/**
 * Create new course
 * 
 * @param {*} course
 * @returns 
 */
export function Create(course) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: course
    });
}

/**
 * Get all courses
 * 
 * @param {*} params
 * @returns 
 */
export function Courses(params) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        data: params
    });
}

/**
 * Get course by id
 * 
 * @param {*} courseId 
 * @returns 
 */
export function Course(courseId) {
    return axios(`${URL}/${courseId}`, {
        method: 'GET',
        headers: getHeader()
    });
}

/**
 * Count
 * 
 * @param {*} params 
 * @returns 
 */
export function Count(params) {
    return axios(`${URL}/count`, {
        method: 'GET',
        headers: getHeader(),
        data: params
    });
}