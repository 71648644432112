import React from "react";
import Label from "./Label";
import Select from "./Select";

/**
 * Dropdown with Label
 * 
 * @param {*} props 
 * @returns 
 */
export default function LabelSelect(props) {
    const {
        htmlFor,
        text,
        id,
        name,
        placeholder,
        className,
        onChange,
        required,
        options,
        optionId,
        value,
        optionText } = props;

    return (
        <>
            <Label
                htmlFor={htmlFor}
                text={text} />
            <Select
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                required={required}
                className={className}
                onChange={onChange}
                options={options}
                optionId={optionId}
                optionText={optionText} />
        </>
    );
}