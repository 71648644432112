import axios from "axios"

import { getHeader } from "../../_helpers/common";

const URL = `${process.env.REACT_APP_API_URL}users`;

/**
 * Register
 * 
 * @param {*} user 
 * @returns 
 */
export function Create(user) {
    return axios(URL, {
        method: 'POST',
        headers: getHeader(),
        data: user
    });
}

/**
 * List users
 * 
 * @param {*} param 
 * @returns 
 */
export function Users(param) {
    return axios(URL, {
        method: 'GET',
        headers: getHeader(),
        data: param
    });
}

/**
 * Login with the application
 * 
 * @param {*} param 
 * @returns 
 */
export function DoLogin(param) {
    return axios(`${URL}/authenticate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: param
    });
}